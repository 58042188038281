import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import data from "../data/mobilemappingsystems.json"

const MobileMappingSystems = () => {
  const thisYear = new Date().getFullYear()
  const title = `Top Mobile Mapping Systems in ${thisYear}`
  const description = `Looking to buy a mobile mapping system? This page lists the top manufacturers and suppliers of mobile mapping systems in ${thisYear}`
  return (
    <Layout>
      <SEO title={title} description={description} />

      <section className="container pt-8 pt-md-12 pb-8 pb-md-12 bg-gradient text-center">
        <div className="container">
          <h1 className="display-3 font-weight-bold">{title}</h1>
          <p className="lead font-weight-bold mb-6 mb-md-8 text-gray-800">An overview and buyer's guide.</p>
          <p className="pt-5">
            <strong>Mobile Mapping Systems</strong> are sophisticated geospatial data collection tools mounted on
            vehicles. They integrate cutting-edge technology like high-resolution cameras, LiDAR, radar, and other
            remote sensing systems with precise navigation sensors. This combination enables the creation of highly
            accurate GIS data, HD maps, and georeferenced images and video.
          </p>
          <Link to="/contact/" className="btn btn-sm btn-outline-primary mr-3 event-contact">
            Ask a question
          </Link>
          <Link to="/contact/" className="event-contact">
            List your solution
          </Link>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3">
          {data.map((item) => {
            return (
              <div className="col mb-4" key={item.Name}>
                <div className="card border border-gray-800" itemscope itemtype="http://schema.org/Product">
                  <div className="card-body">
                    <h5 className="card-title mb-1" itemprop="name">
                      {item.Name}
                    </h5>
                    <p className="font-size-sm text-muted mb-0" itemprop="brand">
                      {item.Manufacturer}
                    </p>
                    <p className="card-text font-size-sm" itemprop="description">
                      {item.Description}
                    </p>
                    <a className="card-link" itemprop="url" href={item.Link}>
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default MobileMappingSystems
